import bg from '../../../assets/images/bg.png';
import ground from '../../../assets/images/ground.png';
import crate0 from '../../../assets/images/crate_0.png';
import crate1 from '../../../assets/images/crate_1.png';
import progress from '../../../assets/images/progress.png';
import progressBg from '../../../assets/images/progress_bg.png';
import clouds from '../../../assets/images/clouds.png';
import mount0 from '../../../assets/images/mountain0.png';
import mount1 from '../../../assets/images/mountain1.png';
import house0 from '../../../assets/images/house0.png';
import house1 from '../../../assets/images/house1.png';
import house2 from '../../../assets/images/house2.png';
import arena from '../../../assets/images/arena.png';
import beach from '../../../assets/images/beach.png';
import palm0 from '../../../assets/images/palm0.png';
import palm1 from '../../../assets/images/palm1.png';
import yacht0 from '../../../assets/images/yacht0.png';
import yacht1 from '../../../assets/images/yacht1.png';
import sun from '../../../assets/images/sun.png';

import beer from '../../../assets/images/smiles/beer.png';
import fire from '../../../assets/images/smiles/fire.png';
import flushed from '../../../assets/images/smiles/flushed.png';
import folded_hands from '../../../assets/images/smiles/folded_hands.png';
import four_leaf_clover from '../../../assets/images/smiles/four_leaf_clover.png';
import money_mouth from '../../../assets/images/smiles/money_mouth.png';
import pile_of_poo from '../../../assets/images/smiles/pile_of_poo.png';
import screaming_in_fear from '../../../assets/images/smiles/screaming_in_fear.png';
import slot_machine from '../../../assets/images/smiles/slot_machine.png';
import sunglasses from '../../../assets/images/smiles/sunglasses.png';
import swearing from '../../../assets/images/smiles/swearing.png';
import tongue from '../../../assets/images/smiles/tongue.png';

import openSmiles from '../../../assets/images/smiles/button_open.png';
import closeSmiles from '../../../assets/images/smiles/button_close.png';

import eImageType from './eImageType';
import * as PIXI from 'pixi.js';
import eSmileType from './eSmileType';

export default new class ImagePreloader {
  constructor() {
    this.imagesData = [
      { type: eImageType.EIT_BG, src: bg },
      { type: eImageType.EIT_CLOUDS, src: clouds },
      { type: eImageType.EIT_GROUND, src: ground },
      { type: eImageType.EIT_CRATE_0, src: crate0 },
      { type: eImageType.EIT_CRATE_1, src: crate1 },
      { type: eImageType.EIT_PROGRESS, src: progress },
      { type: eImageType.EIT_PROGRESS_BG, src: progressBg },
      { type: eImageType.EIT_PALM_0, src: palm0 },
      { type: eImageType.EIT_PALM_1, src: palm1 },
      { type: eImageType.EIT_MOUNT0, src: mount0 },
      { type: eImageType.EIT_MOUNT1, src: mount1 },
      { type: eImageType.EIT_HOUSE_0, src: house0 },
      { type: eImageType.EIT_HOUSE_1, src: house1 },
      { type: eImageType.EIT_HOUSE_2, src: house2 },
      { type: eImageType.EIT_ARENA, src: arena },
      { type: eImageType.EIT_BEACH, src: beach },
      { type: eImageType.EIT_YACHT0, src: yacht0 },
      { type: eImageType.EIT_YACHT1, src: yacht1 },
      { type: eImageType.EIT_SUN, src: sun },

      { type: eSmileType.EST_BEER, src: beer },
      { type: eSmileType.EST_FIRE, src: fire },
      { type: eSmileType.EST_FLUSHED, src: flushed },
      { type: eSmileType.EST_FOLDED_HANDS, src: folded_hands },
      { type: eSmileType.EST_FOUR_LEAF_CLOVER, src: four_leaf_clover },
      { type: eSmileType.EST_MONEY_MOUTH, src: money_mouth },
      { type: eSmileType.EST_PILE_OF_POO, src: pile_of_poo },
      { type: eSmileType.EST_SCREAMING_IN_FEAR, src: screaming_in_fear },
      { type: eSmileType.EST_SLOT_MACHINE, src: slot_machine },
      { type: eSmileType.EST_SUNGLASSES, src: sunglasses },
      { type: eSmileType.EST_SWEARING, src: swearing },
      { type: eSmileType.EST_TONGUE, src: tongue },

      { type: eImageType.EIT_OPEN_SMILES, src: openSmiles },
      { type: eImageType.EIT_CLOSE_SMILES, src: closeSmiles },
    ];
  }

  startLoading(app, onComplete) {
    this.onLoadingComplete = onComplete;
    for (let item of this.imagesData) {
      PIXI.Loader.shared.add(item.type, item.src);
    }
    PIXI.Loader.shared.onComplete.add(this.onAnimationLoaded.bind(this));
    PIXI.Loader.shared.onError.add(this.onError.bind(this));
  }

  onError(e) {
    console.error(e);
    this.hasError = true;
  }

  onAnimationLoaded(_, resources) {
    if (this.hasError) return;
    this.resources = resources;
    this.onLoadingComplete();
  };

  createImage(type) {
    return new PIXI.Sprite(this.getTexture(type));
  }

  getTexture(type) {
    return this.resources[type].texture;
  }

  getSrc(type) {
    const index = this.imagesData.findIndex((item) => item.type === type);
    if(index === -1){
      console.error(`Unlisted image type: ${type}`)
    }
    return this.imagesData[index].src;
  }
}
