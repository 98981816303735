import GlobalDispatcher from '../../events/GlobalDispatcher';
import eWSEventId from '../../api/eWSEventId';
import AnimationLoader from '../../preloaders/animations/AnimationLoader';
import eAnimationType from '../../preloaders/animations/eAnimationType';

export default class ControllerFlags {
  constructor(container, groundContainer) {
    this.flags = [];
    this.container = container;
    this.groundContainer = groundContainer;
    GlobalDispatcher.add(eWSEventId.EWEI_TAKE, this.spawnFlag, this);
  }

  spawnFlag(data) {
    const flag = AnimationLoader.createAnimation(eAnimationType.EAT_FLAG);
    const animationName = data.params.requestId ? 'flag_deploy_red' : 'flag_deploy_blue';

    flag.state.setAnimation(0, animationName, false);

    const container = new PIXI.Container();
    container.addChild(flag);

    container.y = 530;
    container.x = -this.groundContainer.x + 400;

    this.groundContainer.addChild(container);
    this.flags.push(container);
  }

  reset() {
    for (const item of this.flags) {
      item.destroy();
    }
    this.flags = [];
  }
}
