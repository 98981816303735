import eImageType from '../../preloaders/images/eImageType';

export const eClusterType = {
  ECT_DAY: 'day',
  ECT_LOCATIONS: 'locations',
  ECT_YACHTS: 'yachts',
  ECT_MOUNTS: 'mounts'
};

export const clustersInfo = {
  [eClusterType.ECT_DAY]: {
    [eClusterType.ECT_LOCATIONS]: [
      {
        obj: [
          {
            image: eImageType.EIT_HOUSE_0,
            x: 0,
            y: -360,
          },
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_HOUSE_1,
            x: 0,
            y: -310,
          },
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_HOUSE_2,
            x: 0,
            y: -365,
          }
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_ARENA,
            x: 0,
            y: -115,
          }
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_BEACH,
            x: 0,
            y: -202,
          },
          {
            image: eImageType.EIT_YACHT0,
            x: 50,
            y: -10,
          }
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_BEACH,
            x: 0,
            y: -202,
          }
        ]
      },
    ],
    [eClusterType.ECT_MOUNTS]: [
      {
        obj: [
          {
            image: eImageType.EIT_MOUNT0,
            x: 0,
            y: -185,
          },
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_MOUNT1,
            x: 0,
            y: 0,
          },
        ]
      },
    ],
    [eClusterType.ECT_YACHTS]: [
      {
        obj: [
          {
            image: eImageType.EIT_YACHT0,
            x: 10,
            y: -10,
          }
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_YACHT1,
            x: 10,
            y: -30,
            scale: 0.9
          }
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_YACHT0,
            x: 350,
            y: 15,
            scale: 0.6
          },
          {
            image: eImageType.EIT_YACHT1,
            x: 0,
            y: -20,
            scale: 1.2
          },
        ]
      },
    ],
  },
};
