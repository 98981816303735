import TweenMax from 'gsap';
import * as PIXI from 'pixi.js';
import GlobalDispatcher from '../events/GlobalDispatcher';
import eWSEventId from '../api/eWSEventId';
import eEventType from '../events/eEventType';
import ControllerGround from './elements/ControllerGround';
import ControllerSky from './elements/ControllerSky';
import ControllerSun from './elements/ControllerSun';
import ControllerBGFarObjects from './elements/ControllerBGFarObjects';
import ControllerBGObjects from './elements/ControllerBGObjects';
import ControllerFlags from './elements/ControllerFlags';
import ControllerCratesContainer from './elements/ControllerCratesContainer';
import ManagerResult from '../ManagerResult';
import eGameState from '../models/eGameState';
import GameModel from '../models/GameModel';
import ControllerTrees from './elements/ControllerTrees';

export default class ControllerBG {
  constructor(mainContainer) {
    this.mainContainer = mainContainer;

    this.controllerSky = new ControllerSky(mainContainer);
    this.controllerSun = new ControllerSun(mainContainer);
    this.conrollerBGFarObjects = new ControllerBGFarObjects(mainContainer);
    this.conrollerBGObjects = new ControllerBGObjects(mainContainer);
    this.controllerGround = new ControllerGround(mainContainer);
    this.controllerCratesContainer = new ControllerCratesContainer(mainContainer);
    this.controllerTrees = new ControllerTrees(mainContainer);
    this.controllerFlags = new ControllerFlags(mainContainer, this.controllerGround.container);

    this._buildBlocker();

    GlobalDispatcher.add(eWSEventId.EWEI_START, this.startAnimation, this);
    GlobalDispatcher.add(eWSEventId.EWEI_PROGRESS, this.syncAnimation, this);
    GlobalDispatcher.add(eWSEventId.EWEI_FINISH, this._onFinish, this);

    ManagerResult.controllerBG = this;

    if (GameModel.state === eGameState.EGS_PROGRESS) {
      this.setX(GameModel.currentX);
    }
  }

  async _onFinish() {
    this.stopAnimation();
  }

  startAnimation() {
    TweenMax.killTweensOf(this.blocker);
    this.blocker.alpha = 0;
  }

  setX(value) {
    this.controllerSky.setX(value);
    this.controllerSun.setX(value);
    this.conrollerBGFarObjects.setX(value);
    this.controllerGround.setX(value);
    this.controllerTrees.setX(value);
    this.conrollerBGObjects.setX(value);
    this.controllerCratesContainer.setX(value);
    this.controllerFlags.reset();
  }

  syncAnimation(data) {
    this.controllerSky.syncAnimation(data);
    this.controllerSun.syncAnimation(data);
    this.conrollerBGFarObjects.syncAnimation(data);
    this.controllerGround.syncAnimation(data);
    this.controllerTrees.syncAnimation(data);
    this.conrollerBGObjects.syncAnimation(data);
    this.controllerCratesContainer.syncAnimation(data);
  }

  stopAnimation() {
    this.controllerSky.stopAnimation();
    this.controllerSun.stopAnimation();
    this.conrollerBGFarObjects.stopAnimation();
    this.controllerGround.stopAnimation();
    this.controllerTrees.stopAnimation();
    this.conrollerBGObjects.stopAnimation();
    this.controllerCratesContainer.stopAnimation();
  }

  resetPositions() {
    this.controllerSky.reset();
    this.controllerSun.reset();
    this.conrollerBGFarObjects.reset();
    this.controllerFlags.reset();
    this.controllerGround.reset();
    this.controllerTrees.reset();
    this.conrollerBGObjects.reset();
    this.controllerCratesContainer.reset();
    GlobalDispatcher.dispatch(eEventType.EET_RESET);
  }

  _buildBlocker() {
    if (this.blocker)
      this.blocker.destroy();

    this.blocker = new PIXI.Graphics();
    this.blocker.beginFill('0x000000');
    this.blocker.drawRect(0, 0, 1015, 650);
    this.blocker.alpha = 0;
    this.mainContainer.addChildAt(this.blocker, this.mainContainer.children.length);
  }

  animateBlocker(alpha) {
    return new Promise(((resolve) => TweenMax.to(this.blocker, 0.5, { alpha, onComplete: resolve })))
  }
}
