import ControllerMovingBGBase from './ControllerMovingBGBase';
import ImagePreloader from '../../preloaders/images/ImagePreloader';
import eImageType from '../../preloaders/images/eImageType';
import { getRandomInt } from '../../utils/random';
import GlobalDispatcher from '../../events/GlobalDispatcher';
import eEventType from '../../events/eEventType';
import { rotateByYAxis } from '../../utils/rotateByYAxis';

export default class ControllerTrees extends ControllerMovingBGBase {
  constructor(container) {
    super(container);

    this._spawnTree(eImageType.EIT_PALM_0, 200);
    this._spawnTree(eImageType.EIT_PALM_1, 500);

    this._trees = [];
    this._currentGroundType = eImageType.EIT_GROUND;

    this._imageTypes = {
      [eImageType.EIT_GROUND]: [eImageType.EIT_PALM_0, eImageType.EIT_PALM_1],
    };

    GlobalDispatcher.add(eEventType.EET_COMING_GROUND, this._onGroundComingChanged, this);
  }

  onUpdate() {
    this._trySpawnTree();
    this._tryDespawnTrees();
  }

  _onGroundComingChanged(data) {
    this._currentGroundType = data.params;
  }

  reset() {
    super.reset();
    for (const item of this._trees) {
      item.destroy();
    }
    this._currentGroundType = eImageType.EIT_GROUND;
    this._trees = [];
  }

  _trySpawnTree() {
    if (getRandomInt(500) === 42) {
      const type = this._imageTypes[this._currentGroundType][getRandomInt(1)];
      const tree = this._spawnTree(type, -this.container.x + 1015);
      this._trees.push(tree);
    }
  }

  _tryDespawnTrees() {
    for (let i = 0; i < this._trees.length; i++) {
      const tree = this._trees[i];
      if (-tree.x - tree.width > this.container.x) {
        this._trees.splice(i, 1);
        tree.destroy({ children: true });
      }
    }
  }

  _spawnTree(type, x) {
    const tree = ImagePreloader.createImage(type);
    tree.x = x;
    tree.y = 310;
    if (getRandomInt(1) === 1) rotateByYAxis(tree);
    this.container.addChild(tree);
    return tree;
  }
}
