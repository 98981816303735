import ManagerWS from './managerWS';
import { getUrlParam } from '../utils/url';
import eWSEventId from './eWSEventId';
import GameModel from '../models/GameModel';
import queryString from 'querystring';

const devEnvironments = [/localhost/, /0\.0\.0\.0/, /192\.168\./];
if (devEnvironments.some(regexp => regexp.test(window.location.origin))) {
  const queryParams = queryString.parse(window.location.search.substr(1));
  if (!Object.keys(queryParams).length) {
    getSession();
  }
}

export const requestAuth = async () => {
  const sessionHash = getUrlParam('sid');
  const sessionId = getUrlParam('gid');

  const response = await request('get', '/game_init');

  GameModel._decimal = response.decimals;
  await window.OPWrapperService.init({
    partnerConfig: response.partner_config,
    language: response.lang,
    currencyInfo: {
      currency: response.currency,
      decimals: response.decimals,
    },
  });

  window.OPWrapperService.freeBetsController.setData(response.freebets);

  return await ManagerWS.send({ id: 'auth', sessionId, sessionHash });
};

export const requestReAuth = async () => {
  const sessionHash = getUrlParam('sid');
  const sessionId = getUrlParam('gid');
  return await ManagerWS.send({ id: 'auth', sessionId, sessionHash });
};

export const makeBet = (amount, betModel) => {
  if(GameModel.balance < amount) {
    window.OPWrapperService.showError(window.OPWrapperService.errors.INSUFFICIENT_BALANCE_CLIENT.CODE);
    return ;
  }

  OPWrapperService.ControllerStatistic.bet = amount;
  OPWrapperService.realityCheck.blockedRealityCheck = true;

  betModel.waitingAuth = !!ManagerWS.ws.closed;
  betModel.waitingForResponse = true;
  let params = { amount, id: 'bet', jackpot: window.forceJackpot };
  if (betModel.autoTake) params.autoTake = betModel.takeCoef.toFixed(2);
  ManagerWS.send(params)
    .then((response) => {
      betModel.waitingForResponse = false;
      betModel.setBetInfo(response.betId, response.roundId, response.amount);
      if (window.OPWrapperService.freeBetsController.isFreeBets) {
        window.OPWrapperService.ControllerStatistic.increaseFreeBetsCount();
      } else {
        window.OPWrapperService.ControllerStatistic.increaseSpinCount();
      }
    })
    .catch(() => {
      betModel.waitingForResponse = false;
      betModel.waitingAuth = false;
    });
};

export const takeBet = (betId, betModel) => {
  betModel.waitingForResponse = true;
  return new Promise((resolve, reject) => {
    ManagerWS.send({ betId, id: 'take' })
      .then((data) => {
          betModel.waitingForResponse = false;
          betModel.setBetInfo(null, null);
          OPWrapperService.ControllerStatistic.win = data.winAmount;
          if (!GameModel.bets.find(betModel => !!betModel.betID)) OPWrapperService.realityCheck.blockedRealityCheck = false;
          resolve(data);
        },
        (data) => {
          betModel.waitingForResponse = false;
          betModel.setBetInfo(null, null);
          reject(data);
        })
  })
};

export const cancelBet = (betId, betModel) => {
  betModel.waitingAuth = !!ManagerWS.ws.closed;
  betModel.waitingForResponse = true;
  ManagerWS.send({ betId, id: 'cancel' })
    .then(() => {
      OPWrapperService.ControllerStatistic.canceledBet = betModel.betAmount;
      betModel.waitingAuth = false;
      betModel.waitingForResponse = false;
      betModel.setBetInfo(null, null);
      if (window.OPWrapperService.freeBetsController.isFreeBets) {
        window.OPWrapperService.ControllerStatistic.decreaseFreeBetsCount();
      } else {
        window.OPWrapperService.ControllerStatistic.decreaseSpinCount();
      }
      if (!GameModel.bets.find(betModel => !!betModel.betID)) OPWrapperService.realityCheck.blockedRealityCheck = false;
    });
};

export const sendEmoji = (type) => {
  ManagerWS.send({ id: eWSEventId.EWEI_EMOJI, emojiId: type });
};

export const pong = () => {
  if (window.ping_off) return;
  ManagerWS.send({ id: eWSEventId.EWEI_PING });
};

async function getSession() {
  try {
    const raw = await fetch('https://int.dev.onlyplay.net/integration/request_frame/?game=limbocat&no_redirect&no_op_launcher', {
      method: 'GET',
    });
    const response = await raw.json();
    if (response.url) {
      const searchParams = response.url.split('?')[1];
      window.location = `${window.location.origin}?${searchParams}`;
    }
  } catch (e) {
    console.error(e);
  }
}

let waitingForResponse = false;
const request = async (method, url, params) => {
  if (waitingForResponse) {
    throw new Error('Already waiting for response.')
  }

  if (!navigator.onLine) {
    window.OPWrapperService.showError(window.OPWrapperService.errors.NO_CONNECTION.CODE);
    throw new Error('No Internet connection.')
  }

  waitingForResponse = true;

  const sessionHash = getUrlParam('sid');
  const sessionId = getUrlParam('gid');
  const api = getUrlParam('api');
  url = `https://${api + url}/?sid=${sessionHash}&gid=${sessionId}`;

  if (params)
    url += `&req=${JSON.stringify(params)}`;

  let response = await fetch(url, {
    method,
    // mode: 'no-cors'
  });
  waitingForResponse = false;

  if (response.status === 500) {
    throw new Error('Server Down');
  }

  response = await response.json()
    .catch((error) => {
      console.error(error);
      console.error('Response', response);
      throw error;
    });

  if (!response.success) {
    //9001 - insufficient balance
    window.OPWrapperService.showError(response.code, response.message);
    throw response;
  }
  return response;
};
