import en from '../assets/i18n/en';
import enSocial from '../assets/i18n/en-social';

export default {
  localizations: { en, 'en-social': enSocial },
  lang: 'en',
  fallbackLang: 'en',
  bundle: 'whenlambo',
  localizationBundle: 'limbocat',
};
