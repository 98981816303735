import * as PIXI from 'pixi.js';
import ImagePreloader from '../../preloaders/images/ImagePreloader';
import eImageType from '../../preloaders/images/eImageType';
import GlobalDispatcher from '../../events/GlobalDispatcher';
import eEventType from '../../events/eEventType';
import ControllerMovingBGBase from './ControllerMovingBGBase';

export default class ControllerGround extends ControllerMovingBGBase {
  constructor(container) {
    super(container);
    this.mergeOffset = 264;
    this.groundType = eImageType.EIT_GROUND;
    const lamborghiniContainer = new PIXI.Container();
    lamborghiniContainer.name = 'lamborghini_container';
    this.ground = this._createBg(eImageType.EIT_GROUND, -this.mergeOffset, 530);
    this.ground1 = this._createBg(eImageType.EIT_GROUND, this.ground.width - 2 * this.mergeOffset, 530);

    this.textures = {
      [eImageType.EIT_GROUND]: ImagePreloader.getTexture(eImageType.EIT_GROUND),
    };

    this.container.addChild(this.ground, this.ground1);

    container.addChild(lamborghiniContainer);
    GlobalDispatcher.add(eEventType.EET_CHANGE_GROUND_TEXTURE, this._changeTexture, this);
  }

  onUpdate() {
    super.onUpdate();
    const containerX = Math.round(this.container.x);

    if (containerX < -(this.ground.x + this.ground.width)) {
      this.container.setChildIndex(this.ground, 0);
      this.ground.x = this.ground1.x + this.ground1.width - this.mergeOffset;
      this.ground.texture = this.textures[this.groundType];
      // this.ground.commingEmit = false;
    }
    if (containerX < -(this.ground1.x + this.ground1.width)) {
      this.container.setChildIndex(this.ground1, 0);
      this.ground1.x = this.ground.x + this.ground.width - this.mergeOffset;
      this.ground1.texture = this.textures[this.groundType];
      // this.ground1.commingEmit = false;
    }
    //
    // if (containerX < -(this.ground.x - 1015 - this.mergeOffset) && !this.ground.commingEmit) {
    //   GlobalDispatcher.dispatch(eEventType.EET_COMING_GROUND, this.groundType);
    //   this.ground.commingEmit = true;
    // }
    //
    // if (containerX < -(this.ground1.x - 1015 - this.mergeOffset) && !this.ground1.commingEmit) {
    //   GlobalDispatcher.dispatch(eEventType.EET_COMING_GROUND, this.groundType);
    //   this.ground1.commingEmit = true;
    // }
  }

  reset() {
    super.reset();
    this.ground.x = -this.mergeOffset;
    this.ground1.x = this.ground.width - 2 * this.mergeOffset;
    this.groundType = eImageType.EIT_GROUND;
    this.ground.texture = this.textures[this.groundType];
    this.ground1.texture = this.textures[this.groundType];
  }

  _changeTexture(data) {
    const type = data.params;
    this.groundType = type;
    if (this.ground.x - 1015 > -this.container.x)
      this.ground.texture = this.textures[type];
    if (this.ground1.x - 1015 > -this.container.x)
      this.ground1.texture = this.textures[type];
  }

  _switchTexture(sprite, x) {
    // const switcher = Math.floor(-x / (3 * (sprite.width - this.mergeOffset))) % 2 === 1;
    const textureName = eImageType.EIT_GROUND;
    sprite.texture = this.textures[textureName];
  }
}
