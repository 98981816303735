export default {
  EIT_BG: 'bg',
  EIT_CLOUDS: 'clouds',
  EIT_GROUND: 'ground',
  EIT_CRATE_0: 'crate_0',
  EIT_CRATE_1: 'crate_1',
  EIT_PROGRESS: 'progress',
  EIT_PROGRESS_BG: 'progressBg',
  EIT_MOUNT0: 'mountain0',
  EIT_MOUNT1: 'mountain1',
  EIT_PALM_0: 'palm_0',
  EIT_PALM_1: 'palm_1',
  EIT_HOUSE_0: 'house0',
  EIT_HOUSE_1: 'house1',
  EIT_HOUSE_2: 'house2',
  EIT_ARENA: 'arena',
  EIT_BEACH: 'beach',
  EIT_YACHT0: 'yacht0',
  EIT_YACHT1: 'yacht1',
  EIT_SUN: 'sun',

  EIT_OPEN_SMILES: 'open_smiles',
  EIT_CLOSE_SMILES: 'close_smiles',
}
