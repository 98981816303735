import eWSEventId from './eWSEventId';
import GlobalDispatcher from '../events/GlobalDispatcher';
import { getUrlParam } from '../utils/url';
import ManagerErrors from '../errors/ManagerErrors';
import { requestReAuth } from './wsRequests';
import { pong } from './wsRequests';

export class SocketController {
  constructor(urls, onMessage, onError) {
    this.requestId = 0;
    this.responseCallbacks = {};
    this.callStack = [];
    this.closed = true;

    this.conectedData = [urls, onMessage, onError];

    this.connect(...this.conectedData);
  }

  connect(urls, onMessage, onError) {
    this.webSockets = urls.map(url => {
      const ws = new WebSocket(url);
      ws.addEventListener('open', (e) => this.onOpen(e, onMessage));
      ws.addEventListener('error', onError);
      return ws;
    })
  }

  close() {
    console.log('close ws');
    this.closed = true;
    this.ws.close();
  }

  reconnect() {
    console.log('reconnect ws');
    this.connect(...this.conectedData);
  }

  onOpen(e, onMessage) {
    this.closed = false;
    this.ws = e.target;
    this.webSockets.forEach(socket => {
      if (socket !== this.ws) socket.close();
    })
    this.ws.addEventListener('message', onMessage);
    this.ws.addEventListener('message', this.onMessage.bind(this));
    this.ws.addEventListener('open', this.onOpen.bind(this));
    this.ws.addEventListener('error', this.onError.bind(this));
    this.ws.addEventListener('close', this.onClose.bind(this));

    this.sendWaitingMessage();
  }

  sendWaitingMessage() {
    const authIndex = this.callStack.findIndex((item) => item.message.id === eWSEventId.EWEI_AUTH);
    if (authIndex !== -1) {
      const authMessage = this.callStack.splice(authIndex, 1)[0];
      const resolve = () => {
        authMessage.resolve();
        this.sendWaitingMessage();
      };
      this._sendWithPromise(authMessage.message, resolve, authMessage.reject);
    } else {
      while (this.callStack.length > 0) {
        const item = this.callStack.shift();
        this._sendWithPromise(item.message, item.resolve, item.reject);
      }
    }
  }

  onError(event) {
    console.error(event.message);
  }

  onClose(event) {
    this.closed = true;
  }

  async onMessage(event) {
    let data = '';
    data = JSON.parse(event.data);

    if (data.code) {
      console.error(data.message);
      if (data.hasOwnProperty('requestId')) {
        this.responseCallbacks[data.requestId].reject(data);
        delete this.responseCallbacks[data.requestId];
        return;
      }
    }

    if (data.hasOwnProperty('requestId')) {
      if (data.requestId === -1) return;
      this.responseCallbacks[data.requestId].resolve(data);
      delete this.responseCallbacks[data.requestId];
    }
  }

  send(message) {
    if (!navigator.onLine) {
      window.OPWrapperService.showError(window.OPWrapperService.errors.NO_CONNECTION.CODE);
      throw new Error('No Internet connection.');
    }
    return new Promise((resolve, reject) => {
      try {
        this._sendWithPromise(message, resolve, reject);
      } catch (e) {
        console.warn(e.message);
        this.callStack.push({ message, resolve, reject });
      }
    });
  }

  _sendWithPromise(message, resolve, reject) {
    if (this.closed) throw Error('WebSocket is already in CLOSING or CLOSED state');
    this.ws.send(JSON.stringify({ ...message, requestId: this.requestId }));
    this.responseCallbacks[this.requestId] = { resolve, reject };
    this.requestId++;
  }
}

export default new class ManagerWS {
  constructor() {
    this.lastErrorCode = 0;

    this.handleUrlParams();
    this.connect();
  }

  handleUrlParams() {
    this._serverPrefix = '';
    const api = getUrlParam('api');
    if (/.stage/.test(api)) {
      this._serverPrefix = 'stage.';
    } else if (/.dev/.test(api)) {
      this._serverPrefix = 'dev.';
    }

    this._regionPrefix = api.match(/api-\w+/);
    this._regionPrefix = this._regionPrefix ? this._regionPrefix[0].replace('api', '') : '';
    this._serverName = getUrlParam('sn') ? getUrlParam('sn') : getUrlParam('pid');
    this._domainName = 'onlyplaygames';
  }

  connect() {
    if (this._tryFallbackUrl) this._domainName = 'onlyplay';
    const url = `wss://ws-crash-limbocat${this._regionPrefix}.${this._serverPrefix}${this._domainName}.net/${this._serverName}/limbocat`;

    const wsURLs = [
      url
      // 'ws://192.168.1.54:12010/limbocat',
      // 'ws://192.168.0.102:12010/limbocat',
      // 'ws://localhost:12010/limbocat',
    ]

    this.ws = new SocketController(wsURLs, this.onMessage.bind(this), this.onError.bind(this));
  }

  async onMessage(event) {
    let data = '';
    try {
      data = JSON.parse(event.data);
    } catch (e) {
      const pb = protobuf.Root.fromJSON({
        nested: {
          LevelProgress: {
            fields: {
              coef: {
                id: 1,
                type: 'float'
              },
              x: {
                id: 2,
                type: 'float'
              }
            }
          }
        }
      });
      decodeLevelProgress(event.data);
    }

    if (data.code) {
      this.lastErrorCode = data.code;
      ManagerErrors.handleError(data);
    }

    switch (data.id) {
      case eWSEventId.EWEI_RECONNECT:
        this.reconnect();
        return;
      case eWSEventId.EWEI_PING:
        await this.send({ id: eWSEventId.EWEI_PING });
        return;
      case eWSEventId.EWEI_BET:
      case eWSEventId.EWEI_BALANCE:
        this._updateFreeBets(data.freebets);
        break;
    }

    // console.log(data);
    if (Object.values(eWSEventId).includes(data.id)) {
      data.id === eWSEventId.EWEI_PING && pong();
      GlobalDispatcher.dispatch(data.id, data);
    } else {
      console.warn(`Unhandled ID: ${data.id}`);
    }
  }

  onError(event) {
    if (this.ws.closed && !this._tryFallbackUrl) {
      this._tryFallbackUrl = true;
      this.connect();
    } else {
      window.OPWrapperService.showError(window.OPWrapperService.errors.SOCKET_DISCONNECTED.CODE);
    }
  }

  send(message) {
    return this.ws.send(message);
  }

  reconnect() {
    this.ws.close();
    requestReAuth();
    setTimeout(() => {
      this.ws.reconnect();
    }, +getUrlParam('reconnectTimeout') || +getUrlParam('RT') || 1000);
  }

  _updateFreeBets(freeBetsData) {
    window.OPWrapperService.freeBetsController.setData(freeBetsData);
    window.OPWrapperService.freeBetsController.show();
    window.OPWrapperService.freeBetsController.updateTotalWin();
  }
};
