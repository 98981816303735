import eSoundsType from './eSoundsType';
import * as PIXI from 'pixi.js';

export default new class SoundsPreloader {
  constructor() {

  }

  startLoading() {
    for (const key in eSoundsType) {
      const value = eSoundsType[key];
      PIXI.Loader.shared.add(value, `assets/sounds/${value}.mp3`);
    }

  }
}
