export default {
  EEC_DISCONNECT: 5500,
  EEC_INVALID_TAKE_BET_TIME: 141002,
  EEC_TAKE_WITHOUT_BET: 141003,
  EEC_OFTEN_EMOJI: 146001,
  EEC_MAX_BET_LIMIT: 139002,
  EEC_INVALID_TAKE_BET_TIME_OLD: 4280,
  EEC_TAKE_WITHOUT_BET_OLD: 4285,
  EEC_OFTEN_EMOJI_OLD: 4431,
  EEC_MAX_BET_LIMIT_OLD: 9002,
};
