import ImagePreloader from '../../preloaders/images/ImagePreloader';
import eImageType from '../../preloaders/images/eImageType';
import GlobalDispatcher from '../../events/GlobalDispatcher';
import eEventType from '../../events/eEventType';
import ControllerMovingBGBase from './ControllerMovingBGBase';

export default class ControllerSky extends ControllerMovingBGBase {
  constructor(container) {
    super(container);
    this.mergeOffset = 300;
    this.stepDevider = 6;
    this.bg = this._createBg(eImageType.EIT_BG, -this.mergeOffset, -40);
    this.bg1 = this._createBg(eImageType.EIT_BG, this.bg.width - 2 * this.mergeOffset, -40);

    this.clouds = ImagePreloader.createImage(eImageType.EIT_CLOUDS);
    this.clouds1 = ImagePreloader.createImage(eImageType.EIT_CLOUDS);
    this.clouds1.x = this.clouds1.width;

    this.container.addChild(this.bg1, this.bg, this.clouds, this.clouds1);
  }

  onUpdate() {
    const containerX = Math.round(this.container.x);

    // if (containerX < -(this.bg.x + this.bg.width - this.mergeOffset - 1015)) {
    //   GlobalDispatcher.dispatch(eEventType.EET_BACKGROUND_CHANGE_COMING, eBGTypes.EBT_DAY);
    //   GlobalDispatcher.dispatch(eEventType.EET_CHANGE_GROUND_TEXTURE, eImageType.EIT_GROUND);
    // }

    if (containerX < -(this.bg.x + this.bg.width - this.mergeOffset)) {
      this.bg.x = this.bg1.x + this.bg1.width - this.mergeOffset;
    }

    // if (containerX < -(this.bg1.x + this.bg1.width - this.mergeOffset - 1015)) {
    //   GlobalDispatcher.dispatch(eEventType.EET_BACKGROUND_CHANGE_COMING, eBGTypes.EBT_DAY);
    //   GlobalDispatcher.dispatch(eEventType.EET_CHANGE_GROUND_TEXTURE, eImageType.EIT_GROUND);
    // }

    if (containerX < -(this.bg1.x + this.bg1.width - this.mergeOffset)) {
      this.bg1.x = this.bg.x + this.bg.width - this.mergeOffset;
    }

    if (containerX < -(this.clouds.x + this.clouds.width)) {
      this.clouds.x = this.clouds1.x + this.clouds.width;
    }

    if (containerX < -(this.clouds1.x + this.clouds1.width)) {
      this.clouds1.x = this.clouds.x + this.clouds1.width;
    }
  }

  reset() {
    super.reset();
    this.bg.x = -this.mergeOffset;
    this.bg1.x = this.bg.width - 2 * this.mergeOffset;
    this.clouds.x = 0;
    this.clouds1.x = this.clouds1.width;
  }
}
