import ImagePreloader from '../../preloaders/images/ImagePreloader';
import eImageType from '../../preloaders/images/eImageType';
import ControllerMovingBGBase from './ControllerMovingBGBase';

export default class ControllerSun extends ControllerMovingBGBase {
  constructor(container) {
    super(container);
    this.stepDevider = 50;
    this.sun = ImagePreloader.createImage(eImageType.EIT_SUN);
    this.sun.x = 1000;
    this.sun.y = 395;
    this.container.addChild(this.sun);
  }
}
