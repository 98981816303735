import ImagePreloader from '../../preloaders/images/ImagePreloader';
import { getRandomInt } from '../../utils/random';
import ControllerMovingBGBase from './ControllerMovingBGBase';

import { clustersInfo, eClusterType } from './clustersInfo';
import { rotateByYAxis } from '../../utils/rotateByYAxis';

export default class ControllerBGObjects extends ControllerMovingBGBase {
  constructor(mainContainer) {
    super(mainContainer);
    this.stepDevider = 2;
    this.divider = 1200;
    this.currentClusterType = eClusterType.ECT_DAY;
    this.locationsContainer = new PIXI.Container();
    this.yachtsContainer = new PIXI.Container();
    this.container.addChild(this.yachtsContainer, this.locationsContainer);

    this._clusters = [];

    const locations = clustersInfo[eClusterType.ECT_DAY][eClusterType.ECT_LOCATIONS];
    const yachts = clustersInfo[eClusterType.ECT_DAY][eClusterType.ECT_YACHTS];
    this._spawnCluster(yachts[1], eClusterType.ECT_MOUNTS, 900);
    this._spawnCluster(locations[2], eClusterType.ECT_LOCATIONS, 100);
    this._spawnCluster(locations[locations.length - 1], eClusterType.ECT_LOCATIONS, 800);
    this.lastItem = locations[locations.length - 1];
    // GlobalDispatcher.add(eEventType.EET_BACKGROUND_CHANGE_COMING, this._onBGChanged, this)
  }

  onUpdate() {
    this._trySpawnNewCluster();
    this._tryDespawnClusters();
  }

  reset() {
    super.reset();
    for (const item of this._clusters) {
      item.destroy();
    }
    this._clusters = [];
    this.divider = 1200;
  }

  _onBGChanged(data) {
    this.currentClusterType = data.params;
  }

  _trySpawnNewCluster() {
    if (Math.floor(-this.container.x - this.divider) > 1200) this.divider = -this.container.x;
    if (this.container.x <= -1200 && Math.floor( -this.container.x % this.divider) === 0) {
      let itemInfo;
      const type = getRandomInt(2) === 0 ? eClusterType.ECT_YACHTS : eClusterType.ECT_LOCATIONS;
      const clusters = clustersInfo[this.currentClusterType][type];
      do {
        itemInfo = clusters[getRandomInt(clusters.length - 1)];
      } while (this.lastItem === itemInfo);
      this.lastItem = itemInfo;
      this._spawnTempCluster(itemInfo, type, -this.container.x + 1015);
    }
  }

  _tryDespawnClusters() {
    for (let i = 0; i < this._clusters.length; i++) {
      const cluster = this._clusters[i];
      if (-cluster.x - cluster.width > this.container.x) {
        this._clusters.splice(i, 1);
        cluster.destroy({ children: true });
      }
    }
  }

  _spawnTempCluster(itemInfo, type, x) {
    this._clusters.push(this._spawnCluster(itemInfo, type, x));
    this.divider += this._clusters[this._clusters.length - 1].children[0].width + getRandomInt(600);
  }

  _spawnCluster(itemInfo, type, x) {
    const container = new PIXI.Container();
    for (let j = 0; j < itemInfo.obj.length; j++) {
      const item = itemInfo.obj[j];
      const sprite = ImagePreloader.createImage(item.image);
      sprite.x = item.x;
      sprite.y = item.y;
      sprite.scale.set(item.scale || 1);
      if (type === eClusterType.ECT_YACHTS && getRandomInt(1) === 1) rotateByYAxis(sprite);
      container.addChild(sprite);
    }
    container.y = 420;
    container.x = x;
    if (type === eClusterType.ECT_LOCATIONS) {
      this.locationsContainer.addChild(container);
    } else {
      this.yachtsContainer.addChild(container);
    }
    return container;
  }
}
